<template>
  <div class="por">
    <div class="banner">
      <van-swipe :height="675" :autoplay="3000"  @change="changeIndex" ref="round" class="ovh" :show-indicators="show">
         <van-swipe-item class="fl banner-swipe">
           <img src="./status/homebanner1.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
         </van-swipe-item>
         <van-swipe-item class="fl banner-swipe">
           <img src="./status/homebanner2.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
         </van-swipe-item>
         <van-swipe-item class="fl banner-swipe trans">
           <img src="./status/homebanner3.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
         </van-swipe-item>
      </van-swipe>
    </div>
   <div class="poa pt-24" style="width:100%;top:0">

   <nav-bar></nav-bar>
    <div class="main ovh">
       <div class="fillg">飞观App</div>
       <div class="bigword">
         商业和思想的<br>
         视频<br>
         严选订阅<br>
         服务
       </div>
       <div class="cirbtn-h ovh">
         <div class="cirbtn-h-r fr" @click="changeItem(5)"><van-icon class="ml-4" name="arrow" color="white" size="30" /></div>
         <div class="cirbtn-h-l fr" @click="changeItem(4)"><van-icon class="mr-4" name="arrow-left" color="white" size="30" /></div>
       </div>
       <div class="longbtns ovh">
         <div class="longbtn" :class="{'active':current===0}" @click="changeItem(0)"></div>
         <div class="longbtn" :class="{'active':current===1}" @click="changeItem(1)"></div>
         <div class="longbtn" :class="{'active':current===2}" @click="changeItem(2)"></div>
       </div>
       <div class="download ovh por">
         <div class="search fr ovh">
           <div class="fl ico"><i class="iconfont">&#xe632;</i></div>
           <div class="fl search-word">飞观搜索</div>
           <div class="fl coline"></div>
           <div class="fl vname">{{ videoname }}</div>
         </div>
         <img class="appstore fl" src="./status/appstore.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访" @click="appleDnload">
         <img class="android fl" src="./status/android.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访" @click="android">
         <el-popover
           placement="top-end"
           trigger="hover">
           <img class="imgsize" src="./status/qcode.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
           <img slot="reference" class="wechat fl" src="./status/download.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
         </el-popover>
         <div class="cirbtn ovh">
           <div class="cirbtn-l fl" @click="changeItem(4)"><van-icon class="mr-4" name="arrow-left" color="white" size="30" /></div>
           <div class="cirbtn-r fl" @click="changeItem(5)"><van-icon class="ml-4" name="arrow" color="white" size="30" /></div>
         </div>
       </div>
       <div class="mini-longbtns ovh">
           <div class="mini-longbtn fl"  :class="{'active':current===0}" @click="changeItem(0)"></div>
           <div class="mini-longbtn fl"  :class="{'active':current===1}" @click="changeItem(1)"></div>
           <div class="mini-longbtn fl"  :class="{'active':current===2}" @click="changeItem(2)" style="margin-right:0"></div>
       </div>
       <div class="logo">
         <img src="./status/logo.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
       </div>
       <div class="tipword">
         信息粉尘时代，为您严选一流的内容
       </div>
        <div class="tipword-1">
         信息粉尘时代<br> 为您严选一流的内容
       </div>
       <div class="boxes ovh">
         <div class="redbox fl mb-43 ml-20">
           <div class="big-top">商业</div>
         </div>
         <div class="redbox fl mb-43 mr0">
           <div class="big-top">艺术</div>
         </div>
         <div class="redbox fl mr-0 mb-43 mr-40 ml-20">
           <div class="big-top">文化</div>
         </div>
         <div class="redbox fl mr0">
           <div class="big-top">音乐</div>
         </div>
         <div class="redbox fl ml-20">
           <div class="big-top">建筑</div>
         </div>
         <div class="redbox fl mr0" style="margin-right:0">
           <div class="big-top">电影</div>
         </div>
       </div>
    </div>
    <div class="bg-gray">
      <div class="bg-gray-main ovh">
        <div class="words fr">
          <div class="wordsbig fs-34">
            每天为您带来三部 严选商业现场
          </div>
          <div class="wordssml">
            飞观每天都会为您精选来自商业、思想的影视作品或精选文章。您可以在这里捕捉惊喜。
          </div>
        </div>
         <div class="pic fl">
          <img src="./status/img1.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
        </div>
      </div>
    </div>
    <div class="con-main">
        <div class="words fl">
          <div class="wordsbig fs-36">
            透过商业视频探寻商业真理
          </div>
          <div class="wordssml">
            商业的真理往往被隐藏，需要飞观独具慧眼地为您寻找。商业专题中汇聚了被隐藏和难以察觉的优质商业思维。
          </div>
        </div>
        <div class="pic1 fr">
          <img src="./status/img2.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
        </div>
    </div>
    <div class="bg-gray">
      <div class="bg-gray-main ovh">
        <div class="words fr">
          <div class="wordsbig fs-36">
            <p>汇聚全世界的</p>
            优秀思想
          </div>
          <div class="wordssml">
            开阔视野并发现灵感。这里蕴含了不同的思想，充实并提升自我，为未来赋能。
          </div>
        </div>
        <div class="pic fl">
          <img src="./status/img3.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
        </div>
      </div>
    </div>
    <div class="con-main">
        <div class="words fl">
          <div class="wordsbig w414 fs-36">
            <p>十分钟</p>
            带你了解一个领域
          </div>
          <div class="wordssml">
            十分钟就能带你进入一个领域，你可以了解到来自全国各地人们的职业及人生，还可以接触到与众不同的观点。
          </div>
        </div>
        <div class="pic1 fr">
          <img src="./status/img4.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
        </div>
    </div>

    <!-- 严格把控区域 -->
    <div class="bg-gray h-710">
      <div class="control-main">
           <div class="control-bigword">为您严格把控视频内容</div>
           <div class="control-smlword">飞观独家拥有商业纪录片创作专家如是纪录的内容，协同飞观内容部严选来自全球的经典思想著作，涵盖商业、艺术、哲学、社会、科技、心理等领域，一流的内容，更好的体验，服务于10万+精英人群。</div>
           <div class="comic ovh">
             <div class="comic-box fl ml-190 ovh">
                <div class="comic-img">
                  <img src="./status/img5.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
                </div>
                <div class="comic-word">通过专业的商业内容服务团队，对互联网海量的内容信息进行汇总。</div>
             </div>
             <div class="comic-box fl ovh">
                <div class="comic-img">
                  <img src="./status/img6.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
                </div>
                <div class="comic-word">海量视频进行评估和筛选，对内容进行解析剖析，寻找最具价值的核心内容。</div>
             </div>
             <div class="comic-box fl ovh" style="margin-right:0">
                <div class="comic-img">
                  <img src="./status/img7.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
                </div>
                <div class="comic-word">攫取内容核心精华，在用户没有充足时间的情况下尽可能达到最高效率的思维提升。</div>
             </div>
           </div>
      </div>
    </div>
    <!-- 评论区域 -->
    <div class="comment">
      <div class="com-title">致力于服务泛商业人群</div>
      <div class="scroll">
      <div class="comment-boxes ovh">
        <div class="comment-box fl  ml-20 ml-204">
           <div class="comment-word">大学里老师讲的很多理论知识很高深、晦涩，当时没有认真听，专业全部都荒废了。看起来都是大学老师讲的知识，但是通过生动的案例和有趣的访谈，很多东西变得触手可及了。现在闲得没事儿的时候会拿出来找一个视频看看，马云、巴菲特、谷歌，学习了很多管理和营销领域的知识、方法，每天都有获得感。</div>
           <div class="comment-line"></div>
           <div class="infos ovh">
             <div class="info-head fl">
                  <img src="./status/head1.jpg" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
             </div>
             <div class="info fl">
                <div class="info-name">逐风</div>
                <div class="info-pos">知识付费运营</div>
             </div>
           </div>
        </div>
         <div class="comment-box fl">
           <div class="comment-word">一个专注商业视频的app，很惊喜！用了很多的财经、商业、股票app，这是第一个没有广告和弹窗的app。内容十分精选垂直，适合商业人群观看思考。很喜欢这种调性的app，内容很有启发性，能够学习到不少新知识。最近从这个app上找到了不少工作中case需要的背景资料，帮了我很大的忙！</div>
           <div class="comment-line"></div>
           <div class="infos ovh">
             <div class="info-head fl">
                  <img src="./status/head2.jpg" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
             </div>
             <div class="info fl">
                <div class="info-name">西冷</div>
                <div class="info-pos">咨询公司经理</div>
             </div>
           </div>
        </div>
         <div class="comment-box fl" style="margin-right:0">
           <div class="comment-word">平时撰文写稿就有搜集资料的需求。很多时候需要去网上和图书馆查大量的资料，无意中发现了飞观app后，平时闲的时候就可以看看这些商业视频，很多素材就积累了。app上有长的纪录片，也有十分钟左右的短视频，看起来十分轻松，而且能学到新知识，感觉打开了新世界的大门。</div>
           <div class="comment-line"></div>
           <div class="infos ovh">
             <div class="info-head fl">
                  <img src="./status/head3.jpg" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访">
             </div>
             <div class="info fl">
                <div class="info-name">小牛牛</div>
                <div class="info-pos">财经媒体记者</div>
             </div>
           </div>
        </div>
      </div>
      </div>
      <div class="comment-swipe">
        <van-swipe indicator-color="white" :loop="false" class="ovh">
           <van-swipe-item class="fl">
             <div class="com-swipe-box">
               <div class="com-swipe-word">大学里老师讲的很多理论知识很高深、晦涩，当时没有认真听，专业全部都荒废了。看起来都是大学老师讲的知识，但是通过生动的案例和有趣的访谈，很多东西变得触手可及了。现在闲得没事儿的时候会拿出来找一个视频看看，马云、巴菲特、谷歌，学习了很多管理和营销领域的知识、方法，每天都有获得感。</div>
               <div class="com-swipe-line"></div>
               <div class="com-swipe-infos ovh">
                 <div class="com-infos-head fl">
                   <img src="./status/head1.jpg" alt="">
                 </div>
                 <div class="com-swipe-info fl">
                   <div class="swipe-info-name">逐风</div>
                   <div class="swipe-info-pos">知识付费运营</div>
                 </div>
               </div>
             </div>
           </van-swipe-item>
           <van-swipe-item class="fl">
             <div class="com-swipe-box">
               <div class="com-swipe-word">一个专注商业视频的app，很惊喜！用了很多的财经、商业、股票app，这是第一个没有广告和弹窗的app。内容十分精选垂直，适合商业人群观看思考。很喜欢这种调性的app，内容很有启发性，能够学习到不少新知识。最近从这个app上找到了不少工作中case需要的背景资料，帮了我很大的忙！</div>
               <div class="com-swipe-line"></div>
               <div class="com-swipe-infos ovh">
                 <div class="com-infos-head fl">
                   <img src="./status/head2.jpg" alt="">
                 </div>
                 <div class="com-swipe-info fl">
                   <div class="swipe-info-name">西冷</div>
                   <div class="swipe-info-pos">资讯公司经理</div>
                 </div>
               </div>
             </div>
           </van-swipe-item>
           <van-swipe-item class="fl">
             <div class="com-swipe-box">
               <div class="com-swipe-word">平时撰文写稿就有搜集资料的需求。很多时候需要去网上和图书馆查大量的资料，无意中发现了飞观app后，平时闲的时候就可以看看这些商业视频，很多素材就积累了。app上有长的纪录片，也有十分钟左右的短视频，看起来十分轻松，而且能学到新知识，感觉打开了新世界的大门。</div>
               <div class="com-swipe-line"></div>
               <div class="com-swipe-infos ovh">
                 <div class="com-infos-head fl">
                   <img src="./status/head3.jpg" alt="">
                 </div>
                 <div class="com-swipe-info fl">
                   <div class="swipe-info-name">小牛牛</div>
                   <div class="swipe-info-pos">财经媒体记者</div>
                 </div>
               </div>
             </div>
           </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="bottom-line"></div>
    <div class="open">开启思维加油站</div>
    <div class="bot-download" @click="$router.push('/download')">下载 飞观 APP</div>
    <foot></foot>
   </div>
  </div>
</template>

<script>
import NavBar from '@/components/nav.vue'
import Foot from '@/components/foot.vue'
export default {
  data () {
    return {
      current: 0,
      videoname: '大品牌的幕后英雄',
      show: false
    }
  },
  components: {
    NavBar,
    Foot
  },
  methods: {
    // 点击指示器触发
    changeItem (index) {
      if (index === 4) {
        this.current = this.current - 1
        this.$refs.round.swipeTo(this.current)
        if (this.current === -1) {
          this.current = 2
        }
      } else if (index === 5) {
        this.current = this.current + 1
        this.$refs.round.swipeTo(this.current)
        if (this.current === 3) {
          this.current = 0
        }
      } else if (index === 0) {
        this.$refs.round.swipeTo(0)
      } else if (index === 1) {
        this.$refs.round.swipeTo(1)
        this.videoname = '定位'
      } else if (index === 2) {
        this.$refs.round.swipeTo(2)
        this.videoname = '左宗棠鸡'
      }
    },
    // 轮播图切换触发
    changeIndex (index) {
      this.current = index
      if (index === 0) {
        this.videoname = '大品牌的幕后英雄'
      } else if (index === 1) {
        this.videoname = '定位'
      } else if (index === 2) {
        this.videoname = '左宗棠鸡'
      }
    },
    android () {
      this.$http.get('https://api.fillgreat.com/release/app_update/latest_package_resource')
        .then((res) => {
          if (res.data.code === 0) {
            window.open(res.data.data.update_resource_url)
          } else {
            alert(res.message)
          }
        })
    },
    appleDnload () {
      window.location.href = 'https://apps.apple.com/cn/app/飞观/id1471936108'
    }
  }
}
</script>

<style scoped>
/* 当前按钮样式 */
.active {
    transform: scale(1.3) !important;
    background: white !important;
}
.van-icon {
  margin-top: 12px;
}
.mr-4 {
  margin-right: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.banner {
  width: 100%;
}
.banner-swipe {
  width: 100%;
  height: 675px;
  overflow: hidden;
  position: relative;
}
.banner-swipe img {
  width: 100%;
  background-size: cover;
}
.pt-24 {
  padding-top: 24px;
}
.main {
    width: 1160px;
    margin: 0 auto;
}
.imgsize {
  width: 157px;
  height: 157px;
  border-radius: 7px;
}
.fillg {
  height: 25px;
  font-size:18px;
  font-family:PingFang-SC-Medium,PingFang-SC;
  font-weight:500;
  color:rgba(255,255,255,1);
  line-height:25px;
  text-align: left;
  margin-top: 56px;
  margin-bottom: 27px;
}
.bigword {
  width:288px;
  height:268px;
  font-size:48px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(255,255,255,1);
  line-height:67px;
  text-align: left;
  margin-bottom: 45px;
}
.longbtns {
  position: relative;
}
.longbtn {
  width:32px;
  height:3px;
  background:rgba(218, 214, 214,.5);
  margin-right: 14px;
  float: left;
  margin-bottom: 31px;
  cursor: pointer;
}
.cirbtn-h {
  display: none;
}
.cirbtn-h-l,.cirbtn-h-r {
  width:54px;
  height:54px;
  opacity:0.4;
  border:1px solid rgba(255,255,255,1);
  border-radius: 50%;
  cursor: pointer;
}
.cirbtn-h-r {
  margin-right: 24px;
}
.cirbtn-h-l {
  margin-right: 8px;
}
.download {
  position: relative;
}
.download .appstore,.download .android {
  width:151px;
  border-radius: 7px;
  margin-right: 10px;
  cursor: pointer;
}
.download .wechat {
  width: 54px;
  height: 54px;
  border-radius: 7px;
  margin: 0;
  cursor: pointer;
}
.cirbtn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
}
.cirbtn-l,.cirbtn-r {
  width:52px;
  height:52px;
  opacity:0.4;
  border:1px solid rgba(255,255,255,1);
  border-radius: 50%;
  cursor: pointer;
}
.cirbtn-l {
  margin-right: 19px;
}
.arrow {
  width: 12px;
  height: 20px;
  color: white;
}
.search {
  width:306px;
  height:54px;
  border-radius:10px;
  border:3px solid rgba(255,255,255,1);
  box-sizing: border-box;
}
.search .ico {
  margin:16px 8px 18px 12px;
}
.search div i {
  width:21px;
  height:20px;
  color:rgba(255,255,255,1);
}
.search .search-word {
  height:25px;
  font-size:18px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(255,255,255,1);
  line-height:25px;
  margin-top: 12px;
}
.search .coline {
  width:2px;
  height:32px;
  background:rgba(255,255,255,1);
  vertical-align: center;
  margin: 9px 8px;
}
.search .vname {
  height:25px;
  font-size:18px;
  font-family:PingFang-SC-Medium,PingFang-SC;
  font-weight:500;
  color:rgba(255,255,255,1);
  line-height:25px;
  margin-top: 13px;
}
.mini-longbtns {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 39px;
  display: none;
}
.mini-longbtn {
  width:32px;
  height:3px;
  background:rgb(190, 189, 189);
  margin-right: 14px;
}
.logo {
  width: 88px;
  margin: 180px auto 0;
}
.logo img {
  width: 100%;
  height: 88px;
}
.tipword,.tipword-1 {
  font-size:46px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(54,54,54,1);
  line-height:65px;
  margin: 37px auto 74px;
}
.tipword-1 {
  display: none;
}
.boxes {
  margin-bottom: 139px;
}
.redbox {
  width: 160px;
  height: 128px;
  background-color:#F82828;
  padding-top: 32px;
  margin-right: 40px;
}
.big-top {
  width:92px;
  height:65px;
  font-size:46px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(255,255,255,1);
  line-height:65px;
  margin: 14px auto 0 auto;
}
.sml-bot {
  height:25px;
  font-size:18px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(255,255,255,1);
  line-height:25px;
  margin: 0 auto;
}
.bg-gray {
  width: 100%;
  height:650px;
  background:rgba(247,247,250,1);
}
.bg-gray-main {
  width: 1160px;
  height: 650px;
  margin: 0 auto;
}

.pic {
  width: 560px;
  height: 527px;
  margin:62px 98px 0 0;
}
.pic img {
  width: 100%;
}
.pic1 {
  width: 559px;
  height: 371px;
  margin: 165px 0 124px 0 ;
}
.pic1 img {
  width: 100%;
}
.wordsbig {
  width:382px;
  font-size:46px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(54,54,54,1);
  line-height:65px;
  text-align: left;
  margin-top: 240px;
  margin-bottom: 24px;
}
.wordssml {
  width:470px;
  height:44px;
  font-size:16px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  color:rgba(54,54,54,1);
  line-height:22px;
  letter-spacing:1px;
  text-align: justify;
}
.con-main {
  width: 1160px;
  height: 650px;
  margin: 0 auto;
}
.w414 {
  width: 414px;
}
.control-main {
  width: 992px;
  height: 610px;
  margin: 0 auto;
  padding-top: 40px;
}
.control-bigword {
  width:460px;
  /* height:65px; */
  font-size:46px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(54,54,54,1);
  line-height:65px;
  margin: 0 auto 24px;
}
.control-smlword {
  width:992px;
  font-size:16px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  color:rgba(54,54,54,1);
  line-height:22px;
  letter-spacing:1px;
  margin-bottom: 97px;
}
.comic {
  width: 992px;
  margin: 0 auto;
}
.comic-box {
  width: 303px;
  height: 380px;
  margin-right: 41px;
}
.comic-img {
  width: 100%;
}
.comic-img img {
  width: 100%;
}
.comic-word {
  font-size:16px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  color:rgba(54,54,54,1);
  line-height:22px;
  letter-spacing:1px;
  margin-top: 47px;
  text-align: left;
}
.com-title {
  width:460px;
  font-size:46px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(54,54,54,1);
  line-height:65px;
  margin: 69px auto 63px;
}
.comment {
  width: 100%;
}
.scroll {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
.comment-boxes {
  width:100%;
  height:351px;
  margin: 0 auto 112px;
  display: flex;
  justify-content:space-around;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.comment-box {
  width: 30%;
  height:315px;
  background:rgba(247,247,250,1);
  padding-top: 36px;
}
.comment-word {
  width: 77%;
  height:160px;
  font-size:15px;
  font-family:PingFang-SC-Medium,PingFang-SC;
  font-weight:500;
  color:rgba(54,54,54,1);
  line-height:32px;
  margin: 0 auto 29px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:5;
  text-align: justify;
}
.comment-line {
  width: 92%;
  height:4px;
  background:rgba(216,216,216,1);
  opacity:0.3;
  margin: 0 auto;
}
.infos {
  margin: 29px 0 25px 37px;
}
.info {
  margin-top: 12px;
}
.info-head {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin-right: 27px;
}
.info-head img {
  width: 100%;
  border-radius: 50%;
}
.info-name {
  font-size:18px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(54,54,54,1);
  text-align: left;
}
.comment-swipe {
  display: none;
  margin-bottom: 112px;
}
.com-swipe-box {
  width: 76%;
  height: 315px;
  margin: 0 auto;
  background:rgba(247,247,250,1);
  padding-top: 36px;
}
.com-swipe-word {
  width: 77%;
  height: 166px;
  margin: 0 auto;
  text-align: justify;
  font-size: 16px;
  font-family:PingFang-SC-Medium,PingFang-SC;
  font-weight:500;
  /* overflow: hidden; */
  white-space: normal;
  text-overflow: ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:auto;
}
.com-swipe-line {
  width: 91%;
  height: 4px;
  background:rgba(216,216,216,1);
  opacity:0.3;
  margin: 29px auto 0;
}
.com-swipe-infos {
  width: 87%;
  height: 68px;
  margin: 29px auto;
}
.com-swipe-infos .com-infos-head {
  width: 69px;
  height: 69px;
  border-radius: 50%;
}
.com-swipe-infos .com-infos-head img {
  width: 100%;
  height: 69px;
  border-radius: 50%;
}
.com-swipe-info {
  height: 69px;
}
.swipe-info-name {
  margin-top: 10px;
  text-align: left;
  margin-left: 25px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(54,54,54,1);
}
.swipe-info-pos {
  margin-top: 5px;
  text-align: left;
  margin-left: 25px;
}
.bottom-line {
  width:77%;
  height:5px;
  background:rgba(247,247,250,1);
  margin:0 auto 116px;
}
.open {
  width:322px;
  height:65px;
  font-size:46px;
  font-family:PingFang-SC-Heavy,PingFang-SC;
  font-weight:800;
  color:rgba(54,54,54,1);
  line-height:65px;
  margin: 0 auto 47px;
}
.bot-download {
  width:196px;
  height:53px;
  background:rgba(248,40,40,1);
  margin: 0 auto 105px;
  font-size:16px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:800;
  color:rgba(255,255,255,1);
  line-height:53px;
  letter-spacing:1px;
  cursor: pointer;
}
@media screen and (max-width: 1920px) {
  .banner-swipe img {
    width: 1920px;
    height: 675px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
  }
}
@media screen and (min-width: 1832px) {
  .comment-boxes {
    width: 1832px;
  }
}
@media screen and (max-width: 1200px) {
  .main,.bg-gray-main,.con-main,.control-main {
    width: 878px;
  }
  .cirbtn-l,.cirbtn-r {
    display: none;
  }
  .boxes {
    width: 560px;
    margin: 0 auto 133px;
  }
  .mr-0 {
    margin-right: 0;
  }
  .mb-43 {
    margin-bottom: 43px;
  }
  .control-smlword {
     width: 800px;
     margin: 0 auto 97px;
   }
   .comic {
     width: 660px;
   }
  .comic-box {
    width: 182px;
    margin-right: 57px;
  }
  .bottom-line {
    width: 585px;
  }
  .pic {
    width: 336px;
    margin: 0 72px 0 0;
  }
  .pic img {
    margin-top: 167px;
  }
  .pic1 {
    width: 336px;
  }
  .pic1 img {
    margin-top: 70px;
  }
  .cirbtn-h {
    display: block;
  }
  .comment-boxes {
    width: 2240px;
    justify-content: space-around;
    padding-left: 160px;
  }
}
@media screen and (max-width: 992px) {
  .trans img {
    transform: translateX(-66%)
  }
  .main,.bg-gray-main,.con-main,.control-main {
    width: 100%;
  }
  .pt-24 {
    padding-top: 0;
  }
  .main {
    width: 100%;
  }
  .fillg {
    margin-top: 116px;
  }
  .fillg,.bigword,.longbtns,.appstore {
    margin-left: 52px;
  }
  .search {
    margin-right: 55px;
  }
  .logo,.cirbtn-h {
    display: none;
  }
  .tipword {
    display: none;
  }
  .tipword-1 {
    display: block;
    margin-top: 218px;
    margin-bottom: 92px;
  }
  .pic,.words,.pic1 {
    float: none;
  }
  .wordsbig {
    margin: 53px auto 8px;
    text-align: center;
  }
  .wordssml {
    text-align: center;
    margin: 0 auto 44px;
  }
  .pic {
    margin: 0 auto;
    height: 370px;
  }
  .pic1 {
    margin: 0 auto;
    height: 370px;
  }
  .pic1 img {
    margin-top: 83px;
  }
  .pic img {
    margin-top: 0;
  }
  .comment-boxes {
    padding-left: 118px;
  }
  .control-smlword {
    width: 549px;
    margin: 0 auto 41px;
    text-align: justify;
  }
}
@media screen and (max-width: 900px) {
  .comment-boxes {
    padding-left: 80px;
  }
}
@media screen and (max-width: 850px) {
  .comment-boxes {
    padding-left: 70px;
  }
}
@media screen and (max-width: 800px) {
   .fillg,.wechat,.longbtns {
     display: none;
   }
   .bigword {
     margin-top: 121px;
   }
   .android,.appstore,.search {
     float: none;
   }
   .appstore {
     margin-left: 12px;
   }
   .search {
     margin: 0 auto;
     margin-bottom: 17px;
   }
   .boxes {
     width: 360px;
   }
   .mr0 {
     margin-right: 0;
   }
   .mr-40 {
     margin-right: 40px;
   }
   .comic {
     width: 72%;
     height: 380px;
   }
   .comic-box {
     float: none;
     width: 100%;
     height: 110px;
     margin-bottom: 19px;
   }
   .comic-img {
     width: 145px;
     height: 103px;
     float: left;
   }
   .comic-img img {
     width: 100%;
   }
   .comic-word {
     float: right;
     width: 60%;
     margin-top: 0;
     text-align: justify;
   }
   .wordssml,.control-smlword {
     width: 72%;
   }
   .control-bigword {
     width: 80%;
   }
   .ml-40 {
     margin-left: 40px;
   }
   .mini-longbtns {
     display: block;
   }
}
@media screen and (max-width: 767px) {
  .comment-boxes {
    width: 2060px;
    display: block;
    padding-left: 0px;
  }
  .comment-box {
    width: 584px;
    margin-left: 76px;
  }
}
@media screen and (max-width: 700px) {
  .scroll {
    display: none;
  }
  .comment-swipe {
    display: block;
  }
}
@media screen and (max-width: 576px) {
    .bigword {
      font-size: 36px;
      margin-bottom: 66px;
    }
    .search {
      width: 54%;
      height: 40px;
    }
    .search .ico {
      margin: 10px 3px 18px 7px;
    }
    .search .search-word,.search .vname {
      font-size: 12px;
      margin-top: 6px;
    }
    .search .coline {
      height: 18px;
      margin: 9px 6px;
    }
    .download .android,.download .appstore {
      width: 25.5%;
    }
    .tipword-1 {
      font-size: 36px;
    }
    .wordsbig {
      width: 80%;
    }
    .comic-img {
      width: 35%;
    }
    .bottom-line {
      width: 86%;
    }
    .control-main {
      height: 750px;
    }
    .control-bigword {
      width: 100%;
      font-size: 30px;
    }
    .h-710 {
      height: 710px;
    }
    .com-title {
      width: 100%;
      font-size: 30px;
    }
    .open {
      font-size: 36px;
    }
    .redbox {
      width: 128px;
      height: 110px;
      padding-top: 22px;
    }
    .big-top {
      font-size: 34px;
    }
    .sml-bot {
      font-size: 12px;
    }
    .ml-20 {
      margin-left: 32px;
    }
    .fs-34 {
      font-size: 34px;
    }
    .fs-36 {
      font-size: 36px;
    }
    .fs-28 {
      font-size: 28px
    }
    .fs-32 {
      font-size: 32px;
    }
    .comment-boxes {
      width: 1521px;
    }
    .comment-box {
      width: 467px;
      height: 281px;
    }
    .comment-line {
      width: 430px;
    }
    .comment-word {
      font-size: 13px;
      width: 430px;
      text-align: left;
    }
    .infos {
      margin: 10px 0 10px 16px;
    }
    .com-swipe-word {
      font-size: 13px
    }
}
</style>
